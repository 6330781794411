import { createMuiTheme } from '@material-ui/core/styles'

export const theme = {
  Colors: {
    background: '#FFFFFF',
    background2: '#283A53',
    light: '#FAFAFA',
    dark: '#F0F0F0',
    primary100: '#f5f5f5',
    primary400: '#dedede',
    primary600: '#979797',
    secondary: '#317FB0',
    secondary600: '#3590C4',
    secondary2: '#307DA4',
    tertiary: '#317fa7',
    backgroundBlue: '#378bc7',
    secondaryBackground: 'rgba(49,127,176, 0.38)',
    secondaryBackgroundLight: 'rgba(49,127,176, 0.04)',
    black: '#000',
    hoverBlue: '#317FB0',
    highBlack: 'rgba(0, 0, 0, 0.87)',
    mediumBlack: 'rgba(0, 0, 0, 0.6)',
    disabledBlack: 'rgba(0, 0, 0, 0.38)',
    highWhite: 'rgba(255, 255, 255, 0.87)',
    mediumWhite: 'rgba(255, 255, 255, 0.7)',
    disabledWhite: 'rgba(255, 255, 255, 0.5)',
    borderWhite: 'rgba(255, 255, 255, 0.3)',
    errorRed: '#FF0C3E',
    errorRedBackground: 'rgba(255, 12, 62, 0.04)',
    errorRedBorder: 'rgba(255, 12, 62, 0.4)',
    appendixHighBlack: 'rgba(0, 0, 0, 0.54)',
    appendixDisabled: 'rgba(0, 0, 0, 0.20)',
    gray1: '#818181',
    gray2: '#3c3c3c',
    alert: '#FFC107',
    alert2: '#f5cb11',
    darkBlue: '#12193B',
  },

  Fonts: {
    Family: {
      headline: 'Montserrat',
      body: 'Roboto',
    },
    FontWeight: {
      regular: '400',
      medium: '500',
      bold: '700',
      extraBold: '800',
    },
  },
  Breakpoints: {
    tablet: '1024px',
    mobile: '768px',
  },
  Elevations: {
    dp1:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
  },
  Indexes: {
    mobileMenu: 100,
    stickyItems: 110,
  },
  Transitions: {
    default: '250ms ease-in-out',
  },
  Sizes: {
    headerHeight: '72px',
    mobileHeaderHeight: '64px',
  },
}

export const MATERIAL_THEME = createMuiTheme({
  palette: {
    primary: {
      main: theme.Colors.secondary,
    },
    secondary: {
      main: theme.Colors.secondary600,
    },
    action: {
      disabledBackground: theme.Colors.secondary,
    },
    text: {
      primary: theme.Colors.highBlack,
      secondary: theme.Colors.mediumBlack,
    },
    error: {
      main: theme.Colors.errorRed,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: theme.Colors.background,
        background: theme.Colors.secondary,
        '&:disabled': {
          color: theme.Colors.disabledWhite,
        },
      },
      outlinedPrimary: {
        borderColor: theme.Colors.primary400,
        '&:hover': {
          borderColor: theme.Colors.primary400,
        },
      },
      textPrimary: {
        color: theme.Colors.secondary,
      },
      sizeLarge: {
        minHeight: '48px',
      },
    },
  },
  props: {
    MuiSelect: {
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    },
  },
})

export default theme
